import React, { useEffect, useState } from "react";
import PropTypes from "@arc-fusion/prop-types";
import get from "lodash.get";
import StoryCards from "./StoryCards";
import { checkIsSuppressibleDuplicate } from "~/components/utilities/handle-duplicate-content";
// NOTE: For-deduping
import { useRenderedContentContext } from "~/components/contexts/rendered-content-context";
import { useChainContext } from "~/shared-components/ChainContext";
import { useTableLayoutContext } from "~/shared-components/TableLayoutContext";
import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import GridItem from "~/components/layouts/utilities/grid-item.jsx";
import Warning from "~/shared-components/Warning";
import { getFeedLimit } from "../default.helpers.js";
import { wasLinkVisited } from "~/components/layouts/homepage.helpers";

const Feed = (props) => {
  const {
    id,
    isAdmin,
    fetchedContent,
    overrides,
    noGrid,
    editableContentObj,
    curationIndices,
    rootCustomFields
  } = props;
  const { visitedLinks } = useSandwichLayoutContext();
  const [visitedHack, setVisitedHack] = useState({});

  // NOTE: This is to assure these values are numbers with proper defaults
  let offset = get(overrides, "offset", 0);
  offset = !Number.isNaN(Number.parseInt(offset, 10))
    ? Number.parseInt(offset, 10)
    : 0;

  // NOTE: The subsequent code (useEffect, Warning) needs to know if the content has been fetched yet.
  // Before being fetched, the content will be: { isLoading: true }
  const isLoading = get(fetchedContent, "isLoading", false);
  // NOTE: Needed for client-side content sources
  const [isReadyToUpdate, setReadyToUpdate] = useState(!isLoading);

  // NOTE: For de-duping
  const { renderedContent, isDifferentListOfRenderedContent } =
    useRenderedContentContext();
  const chainOverrides = useChainContext();
  const { useDesktopOrdering } = chainOverrides;
  const [dedupeTally, setDedupTally] = useState(0);
  const initItems = get(fetchedContent, "items", []).slice(offset);
  const [items, setItems] = useState([...initItems]);

  useEffect(() => {
    if (!isLoading) {
      setReadyToUpdate(true);
      let nextTally = 0;
      const nextItems = initItems?.filter((item) => {
        // START: check is duplicate
        const { desktopInfo, mobileInfo } = checkIsSuppressibleDuplicate({
          renderedContent,
          rootCustomFields,
          chainOverrides,
          curationIndices,
          // featureId: id,
          content: item,
          overrides
        });
        // END: check is duplicate
        const isSuppressibleDuplicate = (
          useDesktopOrdering ? desktopInfo : mobileInfo
        ).isSuppressibleDuplicate;
        nextTally += isSuppressibleDuplicate ? 1 : 0;
        return !isSuppressibleDuplicate;
      });

      // NOTE: Set items if they've changed
      setItems((prev) => {
        if (isDifferentListOfRenderedContent(nextItems, prev)) {
          setDedupTally(nextTally);
          return nextItems;
        }
        return prev;
      });
    }
  }, [
    isLoading,
    isDifferentListOfRenderedContent,
    useDesktopOrdering,
    renderedContent,
    rootCustomFields,
    chainOverrides,
    curationIndices,
    // featureId: id,
    initItems,
    overrides
  ]);

  const limit = getFeedLimit({ items, overrides, useOffset: false });

  // NOTE: In the admin, don't let the limit go 0, otherwise the feature
  // won't come back unless the admin is reloaded
  const limitToUse = isAdmin && limit === 0 ? 1 : limit;

  const { updateItemsPerFeatureMapFeedAdjustments, featureId } =
    useTableLayoutContext();

  useEffect(() => {
    // If your feature will render a feed or if you render
    // more than a single item that will use layout variables that
    // feature needs to let the top table know how many items it is rendering.
    // The responsive layout of the top table changes based on the number of items in a
    // column. So if that number is more than one per feature the top table will need to
    // be notified of this.

    // NOTE: Don't update until content has been fetched and properly inited, i.e. isReadyToUpdate
    if (isReadyToUpdate && updateItemsPerFeatureMapFeedAdjustments) {
      updateItemsPerFeatureMapFeedAdjustments(featureId, limitToUse);
    }
  }, [
    isReadyToUpdate,
    updateItemsPerFeatureMapFeedAdjustments,
    featureId,
    limitToUse
  ]);

  useEffect(() => {
    if (isReadyToUpdate) {
      // Feeds aren't propertly updating the visited class unless something forces
      // a re-render, putting this value in state forces that re-render
      // The isReadyToUpdate dependency and if condition ensure that this only runs once
      // when the feed content is loaded and will not incur the infinite loop
      const newVisitedHack = { ...visitedHack };
      // NOTE: offset handled when getting items
      items.slice(0, limit).forEach((item) => {
        if (!newVisitedHack[item.canonical_url]) {
          newVisitedHack[item.canonical_url] = wasLinkVisited(
            visitedLinks,
            item.canonical_url
          );
        }
      });
      setVisitedHack(newVisitedHack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyToUpdate]);

  if (isAdmin && isReadyToUpdate && limit === 0) {
    const dedupeMsg = dedupeTally
      ? ` ADDITIONAL INFO: ${dedupeTally} stories have been deduped.`
      : "";
    const { msg, level } = fetchedContent
      ? {
          level: "info",
          msg: `This flex feature has no content. It is visible in the admin, but not on the published page. The page/chain/table will adjust to its absence when published -- up to and including complete annihilation of the chain.${dedupeMsg}`
        }
      : {
          level: "warning",
          msg: "The feed content backing this feature is invalid or malfunctioning. Fix or remove it."
        };
    return (
      <GridItem
        index={0}
        overrides={overrides}
        featureLabel="The Flex Feature"
        placementRequirements={{
          inMultiTableChain: true,
          illegalContent: ["prism-placeholder-feed"]
        }}
      >
        <Warning message={msg} level={level} />
      </GridItem>
    );
  }

  return (
    items
      // NOTE: offset handled when getting items
      .slice(0, limit)
      .map((item, index) => {
        return (
          <StoryCards
            key={index}
            id={id}
            index={index}
            isAdmin={isAdmin}
            fetchedContent={item}
            overrides={overrides}
            curationIndices={curationIndices}
            noGrid={noGrid}
            editableContentObj={editableContentObj}
            isFeed={true}
            isEditable={false}
            isVisited={visitedHack[item.canonical_url]}
          />
        );
      })
  );
};

Feed.propTypes = {
  id: PropTypes.string,
  fetchedContent: PropTypes.object,
  rootCustomFields: PropTypes.object,
  overrides: PropTypes.object,
  curationIndices: PropTypes.object,
  noGrid: PropTypes.bool,
  editableContentObj: PropTypes.object,
  isAdmin: PropTypes.bool
};

export default React.memo(Feed);
