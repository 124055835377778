import get from "lodash.get";

/**
 * Receives anglerfish content object and creates an aspect ratio (width/height = number)
 *
 */
export default function createAspectRatio(data) {
  let width = Number.parseInt(get(data, "width", undefined), 10);
  width = !Number.isNaN(width) && width >= 0 ? width : undefined;
  let height = Number.parseInt(get(data, "height", undefined), 10);
  height = !Number.isNaN(height) && height > 0 ? height : undefined;

  if (typeof width === "number" && typeof height === "number") {
    return width / height;
  }

  return undefined;
}
