import createAspectRatio from "../anglerfish/aspect-ratio";
import createCaptionAndCredits from "../anglerfish/caption-and-credits";

function enrichWithFusionAdditions(data) {
  const withFusionAdditions = {
    ...data,
    fusion_additions: {
      aspect_ratio: createAspectRatio(data),
      caption_info: createCaptionAndCredits(data)
    }
  };

  return withFusionAdditions;
}

/**
 * Transformation function that is used in content sources to manipulate anglerfish documents
 * coming from a content source.
 *
 * @param {object} data typical anglerfish image
 * @returns {object} transformed content
 *
 * */
export const transform = (data) => {
  return enrichWithFusionAdditions(data);
};
